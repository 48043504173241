import * as React from 'react'

import Layout from 'layouts/main'
import SubscriptionLayout from '../components/subscription/subscriptionLayout'

const SubscriptionPage = () => (
  <Layout title={'Subscription'}>
    {/* <SubscriptionLayout /> */}
  </Layout>
)

export default SubscriptionPage
